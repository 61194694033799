import React from "react";

export default function SeedsSVGIcon(props) {
  return (
    <svg
      className="absolute z-[1]"
      style={{
        width: `${props.width}`,
        left: `${props.left}px`,
        right: `${props.right}px`,
        top: `${props.top}px`,
        bottom: `${props.bottom}px`,
        transform: `rotate(${props.rotate}deg)`
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1030.67 108.38"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: ".cls-1,.cls-3{fill:#f7e6ca;}.cls-1,.cls-2{opacity:0.7;}"
          }}
        />
      </defs>
      <title>Asset 6</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Seedlings">
          <path
            id="Seed_5"
            data-name="Seed 5"
            className="cls-1"
            d="M928.52,104.4l-1.2-1.38a10.42,10.42,0,0,0,.59-3.39c-.11-1.08.53-2.21.44-3.36a3.23,3.23,0,0,1,.12-1.2c.52-1.53,1.19-3,1.62-4.59a38.68,38.68,0,0,1,2.63-5.8,37,37,0,0,1,4.85-7.79,5.41,5.41,0,0,0,1.09-1.76c.3-1.08,1.3-1.47,2.26-1.91a2.22,2.22,0,0,1,1.15,0,10.3,10.3,0,0,1,3.5,1.62A36.42,36.42,0,0,1,949,77.31a1.57,1.57,0,0,1,.74,1.79,23,23,0,0,1-1,3.28c-.92,2-2.05,3.91-3,5.89A27.47,27.47,0,0,1,939,96.45c-.7.63-1.36,1.3-2,2a8,8,0,0,1-.8.73,15.26,15.26,0,0,0-3.56,3.47A4.2,4.2,0,0,1,928.52,104.4Zm3.92-6.72a14.09,14.09,0,0,1,1.78-1.82c2.41-1.69,4.09-3.88,5.94-6a6.3,6.3,0,0,0,1.3-1.67c.68-1.77,2-3.32,2.31-5.24.05-.37.4-.71.63-1.06a4.93,4.93,0,0,0,1-3.46c-.18-.12-.36-.24-.55-.35-.63-.36-1-1.21-2-.92a10.8,10.8,0,0,0-2.16,1.18c-.18.11-.27.33-.39.49a46.46,46.46,0,0,0-4.78,7.81c-.27.56-.48,1.13-.79,1.68a28.65,28.65,0,0,0-2.6,6.85A5.15,5.15,0,0,0,932,96.6C932,96.89,932.22,97.18,932.44,97.68Z"
          />
          <path
            id="Seed_2"
            data-name="Seed 2"
            className="cls-1"
            d="M675.77,2.59a9.83,9.83,0,0,1,5.7.82,2.17,2.17,0,0,0,.65.22c2.43.39,3.94,1.92,5.57,3.27a6.78,6.78,0,0,1,1.51,2.69c1.25,2.63.51,5.16-.68,7.58a7.78,7.78,0,0,1-3.31,3.34A22.35,22.35,0,0,1,679.59,23a5.22,5.22,0,0,0-1.09.38,7.06,7.06,0,0,1-4.38.4c-.78-.14-1.54-.33-2.32-.43A12.23,12.23,0,0,1,665.92,21a5.83,5.83,0,0,1-1.24-1.43c-.32-.45-.48-1-.77-1.46a7.42,7.42,0,0,1-.18-7.15,10.5,10.5,0,0,1,3.3-4.8c.18-.13.31-.34.51-.4a15.7,15.7,0,0,0,4.55-2.45,5.09,5.09,0,0,1,2-.58A12,12,0,0,1,675.77,2.59Zm5.46,4.29c-.38,0-.58,0-.68.07a2.43,2.43,0,0,1-2.64.24,7.17,7.17,0,0,0-3-.31,8.8,8.8,0,0,0-6,2.4c-2.42,2.12-3.42,5.05-1.36,8.3,1,1.57,4.47,3.44,6.83,2.56a13.62,13.62,0,0,1,3.67-.76,9,9,0,0,0,6.52-3.6,4.9,4.9,0,0,0-.47-6.7C683.16,8.25,682.1,7.55,681.23,6.88Z"
          />
          <path
            id="Seed_4"
            data-name="Seed 4"
            className="cls-1"
            d="M934.13,17.6c-1.8-.12-3.61-.22-5.41-.4a2.26,2.26,0,0,1-1.2-.55c-1.65-1.53-3.2-3.14-4.88-4.64a3.49,3.49,0,0,1-1.3-3.1A5.42,5.42,0,0,1,924,4.2c1-.57,1.72-1.33,2.78-1.84a15.85,15.85,0,0,1,4.53-1.08,19.64,19.64,0,0,1,6.84-.09,2.58,2.58,0,0,1,1.1.3c1.1.93,2.52,1.6,3.19,2.92.58,1.15,1.58,2.14,1.76,3.47A2.76,2.76,0,0,1,944,9.63c-.37.67-.71,1.35-1.1,2-.59,1-1.24,2-1.81,3A5.28,5.28,0,0,1,937,17.12c-.94.14-1.89.22-2.83.32ZM932.94,4.25a6.87,6.87,0,0,0-5.58,2.17c-1.35,1.35-1.52,2.3-.5,3.83a15.2,15.2,0,0,0,2,2.48,3.7,3.7,0,0,0,1.79.89,10.94,10.94,0,0,0,5.36-.4,3.33,3.33,0,0,0,2-1.6c.33-.68.62-1.38.89-2.08a2.12,2.12,0,0,0-.47-2.45,8.85,8.85,0,0,1-1-1.38,3.68,3.68,0,0,0-3.14-1.53C933.89,4.17,933.41,4.23,932.94,4.25Z"
          />
          <g id="Seed_3" data-name="Seed 3" className="cls-2">
            <path
              className="cls-3"
              d="M784,80.51a10.33,10.33,0,0,1,1.58-5.41,19.69,19.69,0,0,0,1.2-2.6,14.21,14.21,0,0,1,2.3-3.93,5.33,5.33,0,0,0,.88-1.18A29.54,29.54,0,0,1,793,62.2a20.6,20.6,0,0,1,1.76-2.12,36.42,36.42,0,0,0,2.68-3.53,43.56,43.56,0,0,0,2.61-3.81c1.46-2.57,3.8-4.58,5.77-6.83a18.14,18.14,0,0,0,1.82-2.57,5.87,5.87,0,0,1,1.53-1.49c2.4-1.84,5.46-2.36,8.39-3.15,1.35-.36,2.77-.58,4.16-.82a7.46,7.46,0,0,1,4.18.4c.76.32,1.66.39,2.47.64a53.54,53.54,0,0,1,5.27,1.8A7,7,0,0,1,836.72,44c1.33,2.28,2.64,4.59,2.71,7.22,1,2.75,0,5.27-1.26,7.78a23.55,23.55,0,0,1-4.52,6.55,11.93,11.93,0,0,1-3.28,2.27,42.67,42.67,0,0,1-11.09,3.48,32,32,0,0,0-5.44,1.43c-3,1.06-6.13,1.81-9.23,2.64a43,43,0,0,0-7.71,2.54c-2,1-4,1.9-5.87,3a29.59,29.59,0,0,1-3.82,1.59,3.19,3.19,0,0,1-1.6.11c-1.07-.16-1.48-.59-1.6-1.49C784,80.91,784,80.71,784,80.51Zm4.33-2.65a3.47,3.47,0,0,0,1,0c1.82-.66,3.69-1.24,5.42-2a40.3,40.3,0,0,1,7.47-2.53c1.29-.31,2.56-.69,3.84-1,1.58-.42,3.17-.85,4.76-1.26l4.54-1.16c1.06-.28,2.1-.59,3.16-.87s2.28-.55,3.4-.88c1.87-.54,3.46-1.65,5.47-1.92a1.14,1.14,0,0,0,.42-.19A22.8,22.8,0,0,0,833,61.44,26.76,26.76,0,0,0,835.71,55c0-.2,0-.4.08-.6.65-3.9-1.08-7.23-3.57-10.35a4.52,4.52,0,0,0-1.75-1.31,35.88,35.88,0,0,0-3.76-1.22c-.67-.2-1.4-.28-2.05-.51a7,7,0,0,0-5.28.21,28.22,28.22,0,0,1-3.56,1.09,10.71,10.71,0,0,0-6,3.42,18.45,18.45,0,0,1-3.71,3.9,5.43,5.43,0,0,0-1,1.09,27.39,27.39,0,0,0-4,6.35,6,6,0,0,1-.67,1.28c-1.1,1.39-2.26,2.74-3.37,4.11-.93,1.17-1.91,2.31-2.72,3.53q-2.47,3.78-4.77,7.63A9.55,9.55,0,0,0,788.29,77.86Z"
            />
            <path
              className="cls-3"
              d="M803.16,60.42a3.92,3.92,0,0,1-1-.46c-.46-.42-.68-1-.24-1.47a12.23,12.23,0,0,1,2.79-2.68,64.34,64.34,0,0,0,6.24-4.3,26.25,26.25,0,0,1,3.4-1.8,1,1,0,0,1,.84.15.72.72,0,0,1,.19.71,9,9,0,0,1-2.93,3.34c-1.68,1.1-3.38,2.19-5,3.38-1.17.88-2.67,1.45-3.48,2.67C803.86,60.16,803.49,60.25,803.16,60.42Z"
            />
            <path
              className="cls-3"
              d="M820.74,58.79c-.28.43-.4.88-.73,1.08-1.3.75-2.32,1.82-3.86,2.29a2.91,2.91,0,0,0-.8.42c-2.1,1.45-4.66,2.21-7,3.37a2.93,2.93,0,0,1-.64.25,1.54,1.54,0,0,1-1.5-.36,1,1,0,0,1-.16-1.47,7.45,7.45,0,0,1,3.51-2.28c1.95-.72,3.89-1.44,5.83-2.2.85-.34,1.64-.8,2.51-1.12a9,9,0,0,1,2-.42C820.17,58.33,820.43,58.6,820.74,58.79Z"
            />
          </g>
          <g id="Seed_3-2" data-name="Seed 3" className="cls-2">
            <path
              className="cls-3"
              d="M505,53.51a10.33,10.33,0,0,1,1.58-5.41,19.69,19.69,0,0,0,1.2-2.6,14.21,14.21,0,0,1,2.3-3.93,5.33,5.33,0,0,0,.88-1.18A29.54,29.54,0,0,1,514,35.2a20.6,20.6,0,0,1,1.76-2.12,36.42,36.42,0,0,0,2.68-3.53,43.56,43.56,0,0,0,2.61-3.81c1.46-2.57,3.8-4.58,5.77-6.83a18.14,18.14,0,0,0,1.82-2.57,5.87,5.87,0,0,1,1.53-1.49c2.4-1.84,5.46-2.36,8.39-3.15,1.35-.36,2.77-.58,4.16-.82a7.46,7.46,0,0,1,4.18.4c.76.32,1.66.39,2.47.64a53.54,53.54,0,0,1,5.27,1.8A7,7,0,0,1,557.72,17c1.33,2.28,2.64,4.59,2.71,7.22,1,2.75,0,5.27-1.26,7.78a23.55,23.55,0,0,1-4.52,6.55,11.93,11.93,0,0,1-3.28,2.27,42.67,42.67,0,0,1-11.09,3.48,32,32,0,0,0-5.44,1.43c-3,1.06-6.13,1.81-9.23,2.64a43,43,0,0,0-7.71,2.54c-2,1-4,1.9-5.87,3a29.59,29.59,0,0,1-3.82,1.59,3.19,3.19,0,0,1-1.6.11c-1.07-.16-1.48-.59-1.6-1.49C505,53.91,505,53.71,505,53.51Zm4.33-2.65a3.47,3.47,0,0,0,1,0c1.82-.66,3.69-1.24,5.42-2a40.3,40.3,0,0,1,7.47-2.53c1.29-.31,2.56-.69,3.84-1,1.58-.42,3.17-.85,4.76-1.26l4.54-1.16c1.06-.28,2.1-.59,3.16-.87s2.28-.55,3.4-.88c1.87-.54,3.46-1.65,5.47-1.92a1.14,1.14,0,0,0,.42-.19A22.8,22.8,0,0,0,554,34.44,26.76,26.76,0,0,0,556.71,28c0-.2,0-.4.08-.6.65-3.9-1.08-7.23-3.57-10.35a4.52,4.52,0,0,0-1.75-1.31,35.88,35.88,0,0,0-3.76-1.22c-.67-.2-1.4-.28-2.05-.51a7,7,0,0,0-5.28.21,28.22,28.22,0,0,1-3.56,1.09,10.71,10.71,0,0,0-6,3.42,18.45,18.45,0,0,1-3.71,3.9,5.43,5.43,0,0,0-1,1.09,27.39,27.39,0,0,0-4,6.35,6,6,0,0,1-.67,1.28c-1.1,1.39-2.26,2.74-3.37,4.11-.93,1.17-1.91,2.31-2.72,3.53q-2.47,3.78-4.77,7.63A9.55,9.55,0,0,0,509.29,50.86Z"
            />
            <path
              className="cls-3"
              d="M524.16,33.42a3.92,3.92,0,0,1-1-.46c-.46-.42-.68-1-.24-1.47a12.23,12.23,0,0,1,2.79-2.68,64.34,64.34,0,0,0,6.24-4.3,26.25,26.25,0,0,1,3.4-1.8,1,1,0,0,1,.84.15.72.72,0,0,1,.19.71,9,9,0,0,1-2.93,3.34c-1.68,1.1-3.38,2.19-5,3.38-1.17.88-2.67,1.45-3.48,2.67C524.86,33.16,524.49,33.25,524.16,33.42Z"
            />
            <path
              className="cls-3"
              d="M541.74,31.79c-.28.43-.4.88-.73,1.08-1.3.75-2.32,1.82-3.86,2.29a2.91,2.91,0,0,0-.8.42c-2.1,1.45-4.66,2.21-7,3.37a2.93,2.93,0,0,1-.64.25,1.54,1.54,0,0,1-1.5-.36,1,1,0,0,1-.16-1.47,7.45,7.45,0,0,1,3.51-2.28c1.95-.72,3.89-1.44,5.83-2.2.85-.34,1.64-.8,2.51-1.12a9,9,0,0,1,2-.42C541.17,31.33,541.43,31.6,541.74,31.79Z"
            />
          </g>
          <g id="Seed_1" data-name="Seed 1">
            <path
              className="cls-1"
              d="M699.23,108.31a3.7,3.7,0,0,1-3.25-.9,1.16,1.16,0,0,1-.38-1.12,4.27,4.27,0,0,1,1.13-2.31c.63-.75,1.35-1.44,2-2.19a1.83,1.83,0,0,0,.46-.85c.16-.92.25-1.84.36-2.75a4.28,4.28,0,0,1,.07-.72,3.38,3.38,0,0,0,0-1.55,17.39,17.39,0,0,1-.13-2.16,19.34,19.34,0,0,1,.5-4.66,21.05,21.05,0,0,1,1.88-5,48.61,48.61,0,0,1,3.3-5.46,8,8,0,0,0,.43-.75,3.41,3.41,0,0,1,.51-.73c.91-.82,1.84-1.61,2.78-2.41a1.29,1.29,0,0,1,.58-.33,4.31,4.31,0,0,0,2.06-1.23,2.12,2.12,0,0,1,.66-.44A43.17,43.17,0,0,1,716.6,71c.53-.14,1-.44,1.52-.63a12.39,12.39,0,0,1,3.49-.52,2.64,2.64,0,0,0,.28,0,6.48,6.48,0,0,1,4,.54,3.75,3.75,0,0,0,1.52.29,3.15,3.15,0,0,1,3.15,2.8,18.55,18.55,0,0,1-.22,5,25.3,25.3,0,0,0-.65,5.62,9.91,9.91,0,0,1-.25,1.78c-.08.44-.26.86-.32,1.3a10.64,10.64,0,0,1-1,3,22.64,22.64,0,0,1-1.59,3.48c-.44.66-.93,1.3-1.35,2a17,17,0,0,1-3.7,3.62,7.75,7.75,0,0,1-.66.46c-.83.54-1.67,1.07-2.48,1.63a17.28,17.28,0,0,1-2.75,1.49,30.93,30.93,0,0,1-6.1,2c-.47.1-.91.24-1.37.36l-3.27.85a3.44,3.44,0,0,0-1.3.45c-1,.87-2.38.94-3.48,1.56A2.7,2.7,0,0,1,699.23,108.31Zm-.57-2.33a5.59,5.59,0,0,0,2.29-.5,30.64,30.64,0,0,1,5.32-1.73c1.19-.29,2.4-.52,3.56-.88,1.55-.49,3.09-1,4.59-1.63a14.07,14.07,0,0,0,4.5-2.91,16.82,16.82,0,0,1,1.85-1.5,11.91,11.91,0,0,0,4.63-6.22,15.93,15.93,0,0,0,1.17-4c.64-1.3.46-2.69.52-4a6.08,6.08,0,0,1,.4-2.11,9.48,9.48,0,0,0,.61-3.67,9.15,9.15,0,0,1,.14-2.39,1.24,1.24,0,0,0-.77-1.44,9.59,9.59,0,0,0-4-1.11,11.9,11.9,0,0,0-4.61.57c-.13,0-.28.05-.42.08a10.44,10.44,0,0,0-3.61,1.37,10.71,10.71,0,0,1-2.41,1.29,2.46,2.46,0,0,0-.69.42l-3,2.24a4,4,0,0,0-.55.54c-1.32,1.62-2.42,3.35-3.56,5.07a2,2,0,0,0-.16.33,17.72,17.72,0,0,0-1.92,6.63,2.7,2.7,0,0,1-.19.83,3.72,3.72,0,0,0-.07,2.24,6.91,6.91,0,0,1,.1,3.93,7.83,7.83,0,0,0-.34,2.26,6.41,6.41,0,0,1-1.21,3.4,5.4,5.4,0,0,1-1.57,1.55A1.12,1.12,0,0,0,698.66,106Z"
            />
            <path
              className="cls-1"
              d="M712.18,95.66a9.71,9.71,0,0,1-.27-1,1.13,1.13,0,0,1,.42-1.1c.36-.32.68-.67,1.06-1a16.63,16.63,0,0,0,2.73-3.35,9.68,9.68,0,0,0,.82-1.52,4.16,4.16,0,0,1,.54-1,5.1,5.1,0,0,0,1.09-2.21,20.38,20.38,0,0,1,1.07-3.35,3.36,3.36,0,0,0,.2-.94,6,6,0,0,1,.54-2,2.87,2.87,0,0,0,.22-1.06,1.75,1.75,0,0,1,.61-1.06.9.9,0,0,1,1-.14.55.55,0,0,1,.35.71c-.22.86-.44,1.73-.64,2.59-.34,1.49-.69,3-1,4.49a9.28,9.28,0,0,1-.86,2.28,81.4,81.4,0,0,1-5.38,8.33A2.37,2.37,0,0,1,712.18,95.66Z"
            />
            <path
              className="cls-1"
              d="M705.44,96.76a2.86,2.86,0,0,1,.2-.69c.17-.37.45-.71.62-1.08a3.37,3.37,0,0,0,.4-1.26,5.93,5.93,0,0,1,.75-2.55c.29-.64.55-1.28.84-1.92.17-.37.4-.72.58-1.09a25.29,25.29,0,0,1,3.41-4.71c.75-.93,1.58-1.81,2.39-2.71a5.71,5.71,0,0,1,.55-.55,1.08,1.08,0,0,1,1.72.3.73.73,0,0,1-.1.67,27.3,27.3,0,0,1-1.93,2.25c-1.07,1.08-2,2.21-3,3.37a22.53,22.53,0,0,0-1.81,2.72,8.72,8.72,0,0,0-1.24,3.56,7.23,7.23,0,0,1-.79,2.79c-.18.33-.24.7-.39,1a4.57,4.57,0,0,1-.48.86.75.75,0,0,1-.89.17A1.29,1.29,0,0,1,705.44,96.76Z"
            />
          </g>
          <path
            id="Seed_2-2"
            data-name="Seed 2"
            className="cls-1"
            d="M114.75,9.85a9.86,9.86,0,0,1,5.69.82,2.16,2.16,0,0,0,.66.23c2.43.39,3.93,1.92,5.56,3.26a6.79,6.79,0,0,1,1.52,2.7c1.25,2.63.51,5.16-.69,7.57a7.85,7.85,0,0,1-3.31,3.35,21.91,21.91,0,0,1-5.61,2.52,5,5,0,0,0-1.09.39,7,7,0,0,1-4.39.39c-.77-.13-1.53-.33-2.31-.42a12.11,12.11,0,0,1-5.88-2.44,5.63,5.63,0,0,1-1.25-1.42c-.31-.46-.48-1-.77-1.46a7.44,7.44,0,0,1-.18-7.16,10.68,10.68,0,0,1,3.3-4.79c.18-.13.31-.34.52-.41a15.59,15.59,0,0,0,4.55-2.45,5.27,5.27,0,0,1,2-.58A13.87,13.87,0,0,1,114.75,9.85Zm5.45,4.29c-.37,0-.58,0-.67.07a2.44,2.44,0,0,1-2.64.24,7.35,7.35,0,0,0-3-.3,8.76,8.76,0,0,0-6.06,2.4c-2.42,2.11-3.42,5.05-1.35,8.29,1,1.58,4.46,3.45,6.83,2.57a14,14,0,0,1,3.66-.77,9,9,0,0,0,6.52-3.59,4.9,4.9,0,0,0-.46-6.7C122.14,15.52,121.08,14.82,120.2,14.14Z"
          />
          <path
            id="Seed_4-2"
            data-name="Seed 4"
            className="cls-1"
            d="M371.12,28.84c-1.8-.13-3.61-.22-5.41-.4a2.27,2.27,0,0,1-1.2-.56c-1.64-1.53-3.19-3.14-4.88-4.64a3.52,3.52,0,0,1-1.3-3.1,5.42,5.42,0,0,1,2.67-4.7c1-.57,1.72-1.33,2.78-1.84a15.21,15.21,0,0,1,4.53-1.08,19.64,19.64,0,0,1,6.84-.1,2.69,2.69,0,0,1,1.1.3c1.11.94,2.52,1.6,3.19,2.92.58,1.16,1.58,2.15,1.76,3.47a2.8,2.8,0,0,1-.23,1.76c-.37.67-.71,1.34-1.1,2-.59,1-1.24,2-1.81,3A5.27,5.27,0,0,1,374,28.35c-.94.15-1.89.22-2.83.33Zm-1.19-13.36a6.87,6.87,0,0,0-5.58,2.17c-1.35,1.35-1.52,2.31-.5,3.83a15.27,15.27,0,0,0,2,2.49,3.69,3.69,0,0,0,1.79.88,11,11,0,0,0,5.36-.39,3.32,3.32,0,0,0,2.05-1.61c.33-.68.62-1.37.89-2.07a2.13,2.13,0,0,0-.47-2.46,10.06,10.06,0,0,1-1-1.37,3.65,3.65,0,0,0-3.14-1.53C370.88,15.4,370.4,15.46,369.93,15.48Z"
          />
          <path
            id="Seed_4-3"
            data-name="Seed 4"
            className="cls-1"
            d="M214.12,105.84c-1.8-.13-3.61-.22-5.41-.4a2.27,2.27,0,0,1-1.2-.56c-1.64-1.53-3.19-3.14-4.88-4.64a3.52,3.52,0,0,1-1.3-3.1,5.42,5.42,0,0,1,2.67-4.7c1-.57,1.72-1.33,2.78-1.84a15.21,15.21,0,0,1,4.53-1.08,19.64,19.64,0,0,1,6.84-.1,2.69,2.69,0,0,1,1.1.3c1.11.94,2.52,1.6,3.19,2.92.58,1.16,1.58,2.15,1.76,3.47a2.8,2.8,0,0,1-.23,1.76c-.37.67-.71,1.34-1.1,2-.59,1-1.24,2-1.81,3a5.27,5.27,0,0,1-4.09,2.44c-.94.15-1.89.22-2.83.33Zm-1.19-13.36a6.87,6.87,0,0,0-5.58,2.17c-1.35,1.35-1.52,2.31-.5,3.83a15.27,15.27,0,0,0,2,2.49,3.69,3.69,0,0,0,1.79.88,11,11,0,0,0,5.36-.39,3.32,3.32,0,0,0,2.05-1.61c.33-.68.62-1.37.89-2.07a2.13,2.13,0,0,0-.47-2.46,10.06,10.06,0,0,1-1-1.37,3.65,3.65,0,0,0-3.14-1.53C213.88,92.4,213.4,92.46,212.93,92.48Z"
          />
          <path
            id="Seed_4-4"
            data-name="Seed 4"
            className="cls-1"
            d="M135.12,87.84c-1.8-.13-3.61-.22-5.41-.4a2.27,2.27,0,0,1-1.2-.56c-1.64-1.53-3.19-3.14-4.88-4.64a3.52,3.52,0,0,1-1.3-3.1,5.42,5.42,0,0,1,2.67-4.7c1-.57,1.72-1.33,2.78-1.84a15.21,15.21,0,0,1,4.53-1.08,19.64,19.64,0,0,1,6.84-.1,2.69,2.69,0,0,1,1.1.3c1.11.94,2.52,1.6,3.19,2.92.58,1.16,1.58,2.15,1.76,3.47a2.8,2.8,0,0,1-.23,1.76c-.37.67-.71,1.34-1.1,2-.59,1-1.24,2-1.81,3A5.27,5.27,0,0,1,138,87.35c-.94.15-1.89.22-2.83.33Zm-1.19-13.36a6.87,6.87,0,0,0-5.58,2.17c-1.35,1.35-1.52,2.31-.5,3.83a15.27,15.27,0,0,0,2,2.49,3.69,3.69,0,0,0,1.79.88,11,11,0,0,0,5.36-.39,3.32,3.32,0,0,0,2.05-1.61c.33-.68.62-1.37.89-2.07a2.13,2.13,0,0,0-.47-2.46,10.06,10.06,0,0,1-1-1.37,3.65,3.65,0,0,0-3.14-1.53C134.88,74.4,134.4,74.46,133.93,74.48Z"
          />
          <g id="Seed_1-2" data-name="Seed 1">
            <path
              className="cls-1"
              d="M999.29,49.54a3.65,3.65,0,0,1-3.24-.9,1.15,1.15,0,0,1-.38-1.12,4.33,4.33,0,0,1,1.12-2.31c.64-.74,1.36-1.44,2-2.18a2,2,0,0,0,.46-.86c.16-.91.25-1.83.36-2.75a4.07,4.07,0,0,1,.07-.71,3.43,3.43,0,0,0,0-1.56,19.69,19.69,0,0,1,.37-6.81,20.89,20.89,0,0,1,1.88-5,46.34,46.34,0,0,1,3.3-5.46c.17-.24.28-.51.43-.76a3,3,0,0,1,.5-.72c.91-.82,1.85-1.62,2.79-2.42a1.51,1.51,0,0,1,.58-.33,4.31,4.31,0,0,0,2.06-1.23,2.31,2.31,0,0,1,.66-.44,46.48,46.48,0,0,1,4.39-1.72,14.48,14.48,0,0,0,1.53-.63,12.33,12.33,0,0,1,3.49-.51c.09,0,.19,0,.28,0a6.41,6.41,0,0,1,4,.55,4.11,4.11,0,0,0,1.52.29,3.15,3.15,0,0,1,3.15,2.79,19.46,19.46,0,0,1-.22,5,25.9,25.9,0,0,0-.66,5.61,10,10,0,0,1-.25,1.79c-.08.43-.25.86-.31,1.29a10.15,10.15,0,0,1-1.05,3,22.16,22.16,0,0,1-1.58,3.48c-.45.66-.94,1.3-1.35,2a16.8,16.8,0,0,1-3.71,3.62,6.43,6.43,0,0,1-.66.46c-.82.55-1.66,1.08-2.47,1.63a18.66,18.66,0,0,1-2.75,1.5,30.78,30.78,0,0,1-6.11,2c-.46.1-.9.25-1.36.37-1.09.28-2.19.55-3.27.84a3.44,3.44,0,0,0-1.3.45c-1,.87-2.39.94-3.48,1.56A2.4,2.4,0,0,1,999.29,49.54Zm-.56-2.33a5.47,5.47,0,0,0,2.29-.49,30.84,30.84,0,0,1,5.31-1.74c1.19-.28,2.41-.52,3.56-.88,1.56-.49,3.1-1,4.6-1.63a14,14,0,0,0,4.5-2.9,15.93,15.93,0,0,1,1.85-1.5,12,12,0,0,0,4.63-6.22,16.05,16.05,0,0,0,1.17-4c.64-1.3.45-2.69.52-4a5.85,5.85,0,0,1,.39-2.11,9.33,9.33,0,0,0,.62-3.67,9.55,9.55,0,0,1,.13-2.39,1.23,1.23,0,0,0-.76-1.45,9.63,9.63,0,0,0-4-1.11,11.85,11.85,0,0,0-4.6.57,2,2,0,0,1-.42.08,10.59,10.59,0,0,0-3.62,1.38,10.79,10.79,0,0,1-2.4,1.28,2.55,2.55,0,0,0-.69.42l-3,2.24a3.65,3.65,0,0,0-.56.55,61.49,61.49,0,0,0-3.55,5.06,2.59,2.59,0,0,0-.17.33,17.75,17.75,0,0,0-1.91,6.64,3,3,0,0,1-.19.82,3.8,3.8,0,0,0-.08,2.24,6.83,6.83,0,0,1,.1,3.93,8.29,8.29,0,0,0-.33,2.26,6.52,6.52,0,0,1-1.21,3.41,5.49,5.49,0,0,1-1.57,1.54A1.13,1.13,0,0,0,998.73,47.21Z"
            />
            <path
              className="cls-1"
              d="M1012.25,36.9c-.11-.36-.22-.67-.28-1a1.18,1.18,0,0,1,.43-1.11c.36-.32.68-.67,1.06-1a16.92,16.92,0,0,0,2.73-3.34A10.69,10.69,0,0,0,1017,29a4.61,4.61,0,0,1,.54-1,5.06,5.06,0,0,0,1.09-2.2,19.57,19.57,0,0,1,1.07-3.36,3.66,3.66,0,0,0,.2-.94,5.91,5.91,0,0,1,.54-2,2.86,2.86,0,0,0,.21-1.06,1.83,1.83,0,0,1,.61-1.06.92.92,0,0,1,1-.14.56.56,0,0,1,.34.72c-.22.86-.43,1.72-.63,2.59-.35,1.49-.69,3-1,4.48a10.16,10.16,0,0,1-.86,2.28,82,82,0,0,1-5.38,8.33A2.36,2.36,0,0,1,1012.25,36.9Z"
            />
            <path
              className="cls-1"
              d="M1005.51,38a2.82,2.82,0,0,1,.2-.68c.17-.38.45-.71.62-1.09a3.13,3.13,0,0,0,.39-1.26,5.89,5.89,0,0,1,.76-2.55c.29-.63.54-1.28.84-1.91.17-.37.4-.73.58-1.1a25.62,25.62,0,0,1,3.41-4.71c.75-.93,1.58-1.81,2.38-2.71a4.76,4.76,0,0,1,.56-.54,1.07,1.07,0,0,1,1.72.3.71.71,0,0,1-.11.67,28.78,28.78,0,0,1-1.92,2.24c-1.07,1.08-2,2.21-3,3.37a20.39,20.39,0,0,0-1.81,2.72,8.88,8.88,0,0,0-1.25,3.56,7,7,0,0,1-.78,2.8c-.18.32-.24.69-.39,1a4.84,4.84,0,0,1-.48.86.74.74,0,0,1-.89.17A1.27,1.27,0,0,1,1005.51,38Z"
            />
          </g>
          <g id="Seed_1-3" data-name="Seed 1">
            <path
              className="cls-1"
              d="M433.29,102.54a3.65,3.65,0,0,1-3.24-.9,1.15,1.15,0,0,1-.38-1.12,4.33,4.33,0,0,1,1.12-2.31c.64-.74,1.36-1.44,2-2.18a2,2,0,0,0,.46-.86c.16-.91.25-1.83.36-2.75a4.07,4.07,0,0,1,.07-.71,3.43,3.43,0,0,0,0-1.56,19.69,19.69,0,0,1,.37-6.81,20.89,20.89,0,0,1,1.88-5,46.34,46.34,0,0,1,3.3-5.46c.17-.24.28-.51.43-.76a3,3,0,0,1,.5-.72c.91-.82,1.85-1.62,2.79-2.42a1.51,1.51,0,0,1,.58-.33,4.31,4.31,0,0,0,2.06-1.23,2.31,2.31,0,0,1,.66-.44,46.48,46.48,0,0,1,4.39-1.72,14.48,14.48,0,0,0,1.53-.63,12.33,12.33,0,0,1,3.49-.51c.09,0,.19,0,.28,0a6.41,6.41,0,0,1,4,.55,4.11,4.11,0,0,0,1.52.29,3.15,3.15,0,0,1,3.15,2.79,19.46,19.46,0,0,1-.22,5,25.9,25.9,0,0,0-.66,5.61,10,10,0,0,1-.25,1.79c-.08.43-.25.86-.31,1.29a10.15,10.15,0,0,1-1.05,3,22.16,22.16,0,0,1-1.58,3.48c-.45.66-.94,1.3-1.35,2a16.8,16.8,0,0,1-3.71,3.62,6.43,6.43,0,0,1-.66.46c-.82.55-1.66,1.08-2.47,1.63a18.66,18.66,0,0,1-2.75,1.5,30.78,30.78,0,0,1-6.11,2c-.46.1-.9.25-1.36.37-1.09.28-2.19.55-3.27.84a3.44,3.44,0,0,0-1.3.45c-1,.87-2.39.94-3.48,1.56A2.4,2.4,0,0,1,433.29,102.54Zm-.56-2.33a5.47,5.47,0,0,0,2.29-.49A30.84,30.84,0,0,1,440.33,98c1.19-.28,2.41-.52,3.56-.88,1.56-.49,3.1-1,4.6-1.63a14,14,0,0,0,4.5-2.9,15.93,15.93,0,0,1,1.85-1.5,12,12,0,0,0,4.63-6.22,16.05,16.05,0,0,0,1.17-4c.64-1.3.45-2.69.52-4a5.85,5.85,0,0,1,.39-2.11,9.33,9.33,0,0,0,.62-3.67,9.55,9.55,0,0,1,.13-2.39,1.23,1.23,0,0,0-.76-1.45,9.63,9.63,0,0,0-4-1.11,11.85,11.85,0,0,0-4.6.57,2,2,0,0,1-.42.08,10.59,10.59,0,0,0-3.62,1.38,10.79,10.79,0,0,1-2.4,1.28,2.55,2.55,0,0,0-.69.42l-3,2.24a3.65,3.65,0,0,0-.56.55,61.49,61.49,0,0,0-3.55,5.06,2.59,2.59,0,0,0-.17.33,17.75,17.75,0,0,0-1.91,6.64,3,3,0,0,1-.19.82,3.8,3.8,0,0,0-.08,2.24,6.83,6.83,0,0,1,.1,3.93,8.29,8.29,0,0,0-.33,2.26,6.52,6.52,0,0,1-1.21,3.41,5.49,5.49,0,0,1-1.57,1.54A1.13,1.13,0,0,0,432.73,100.21Z"
            />
            <path
              className="cls-1"
              d="M446.25,89.9c-.11-.36-.22-.67-.28-1a1.18,1.18,0,0,1,.43-1.11c.36-.32.68-.67,1.06-1a16.92,16.92,0,0,0,2.73-3.34A10.69,10.69,0,0,0,451,82a4.61,4.61,0,0,1,.54-1,5.06,5.06,0,0,0,1.09-2.2,19.57,19.57,0,0,1,1.07-3.36,3.66,3.66,0,0,0,.2-.94,5.91,5.91,0,0,1,.54-2,2.86,2.86,0,0,0,.21-1.06,1.83,1.83,0,0,1,.61-1.06.92.92,0,0,1,1-.14.56.56,0,0,1,.34.72c-.22.86-.43,1.72-.63,2.59-.35,1.49-.69,3-1,4.48a10.16,10.16,0,0,1-.86,2.28,82,82,0,0,1-5.38,8.33A2.36,2.36,0,0,1,446.25,89.9Z"
            />
            <path
              className="cls-1"
              d="M439.51,91a2.82,2.82,0,0,1,.2-.68c.17-.38.45-.71.62-1.09a3.13,3.13,0,0,0,.39-1.26,5.89,5.89,0,0,1,.76-2.55c.29-.63.54-1.28.84-1.91.17-.37.4-.73.58-1.1a25.62,25.62,0,0,1,3.41-4.71c.75-.93,1.58-1.81,2.38-2.71a4.76,4.76,0,0,1,.56-.54,1.07,1.07,0,0,1,1.72.3.71.71,0,0,1-.11.67,28.78,28.78,0,0,1-1.92,2.24c-1.07,1.08-2,2.21-3,3.37a20.39,20.39,0,0,0-1.81,2.72,8.88,8.88,0,0,0-1.25,3.56,7,7,0,0,1-.78,2.8c-.18.32-.24.69-.39,1a4.84,4.84,0,0,1-.48.86.74.74,0,0,1-.89.17A1.27,1.27,0,0,1,439.51,91Z"
            />
          </g>
          <g id="Seed_1-4" data-name="Seed 1">
            <path
              className="cls-1"
              d="M199.29,38.54a3.65,3.65,0,0,1-3.24-.9,1.15,1.15,0,0,1-.38-1.12,4.33,4.33,0,0,1,1.12-2.31c.64-.74,1.36-1.44,2-2.18a2,2,0,0,0,.46-.86c.16-.91.25-1.83.36-2.75a4.07,4.07,0,0,1,.07-.71,3.43,3.43,0,0,0,0-1.56,19.69,19.69,0,0,1,.37-6.81,20.89,20.89,0,0,1,1.88-5,46.34,46.34,0,0,1,3.3-5.46c.17-.24.28-.51.43-.76a3,3,0,0,1,.5-.72c.91-.82,1.85-1.62,2.79-2.42a1.51,1.51,0,0,1,.58-.33,4.31,4.31,0,0,0,2.06-1.23,2.31,2.31,0,0,1,.66-.44,46.48,46.48,0,0,1,4.39-1.72,14.48,14.48,0,0,0,1.53-.63,12.33,12.33,0,0,1,3.49-.51c.09,0,.19,0,.28,0a6.41,6.41,0,0,1,4,.55,4.11,4.11,0,0,0,1.52.29,3.15,3.15,0,0,1,3.15,2.79,19.46,19.46,0,0,1-.22,5,25.9,25.9,0,0,0-.66,5.61,10,10,0,0,1-.25,1.79c-.08.43-.25.86-.31,1.29a10.15,10.15,0,0,1-1,3,22.16,22.16,0,0,1-1.58,3.48c-.45.66-.94,1.3-1.35,2a16.8,16.8,0,0,1-3.71,3.62,6.43,6.43,0,0,1-.66.46c-.82.55-1.66,1.08-2.47,1.63a18.66,18.66,0,0,1-2.75,1.5,30.78,30.78,0,0,1-6.11,2c-.46.1-.9.25-1.36.37-1.09.28-2.19.55-3.27.84a3.44,3.44,0,0,0-1.3.45c-1,.87-2.39.94-3.48,1.56A2.4,2.4,0,0,1,199.29,38.54Zm-.56-2.33a5.47,5.47,0,0,0,2.29-.49A30.84,30.84,0,0,1,206.33,34c1.19-.28,2.41-.52,3.56-.88,1.56-.49,3.1-1,4.6-1.63a14,14,0,0,0,4.5-2.9,15.93,15.93,0,0,1,1.85-1.5,12,12,0,0,0,4.63-6.22,16.05,16.05,0,0,0,1.17-4c.64-1.3.45-2.69.52-4a5.85,5.85,0,0,1,.39-2.11,9.33,9.33,0,0,0,.62-3.67,9.55,9.55,0,0,1,.13-2.39,1.23,1.23,0,0,0-.76-1.45,9.63,9.63,0,0,0-4-1.11,11.85,11.85,0,0,0-4.6.57,2,2,0,0,1-.42.08,10.59,10.59,0,0,0-3.62,1.38,10.79,10.79,0,0,1-2.4,1.28,2.55,2.55,0,0,0-.69.42l-3,2.24a3.65,3.65,0,0,0-.56.55,61.49,61.49,0,0,0-3.55,5.06,2.59,2.59,0,0,0-.17.33,17.75,17.75,0,0,0-1.91,6.64,3,3,0,0,1-.19.82,3.8,3.8,0,0,0-.08,2.24,6.83,6.83,0,0,1,.1,3.93,8.29,8.29,0,0,0-.33,2.26,6.52,6.52,0,0,1-1.21,3.41,5.49,5.49,0,0,1-1.57,1.54A1.13,1.13,0,0,0,198.73,36.21Z"
            />
            <path
              className="cls-1"
              d="M212.25,25.9c-.11-.36-.22-.67-.28-1a1.18,1.18,0,0,1,.43-1.11c.36-.32.68-.67,1.06-1a16.92,16.92,0,0,0,2.73-3.34A10.69,10.69,0,0,0,217,18a4.61,4.61,0,0,1,.54-1,5.06,5.06,0,0,0,1.09-2.2,19.57,19.57,0,0,1,1.07-3.36,3.66,3.66,0,0,0,.2-.94,5.91,5.91,0,0,1,.54-2,2.86,2.86,0,0,0,.21-1.06,1.83,1.83,0,0,1,.61-1.06.92.92,0,0,1,1-.14.56.56,0,0,1,.34.72c-.22.86-.43,1.72-.63,2.59-.35,1.49-.69,3-1,4.48a10.16,10.16,0,0,1-.86,2.28,82,82,0,0,1-5.38,8.33A2.36,2.36,0,0,1,212.25,25.9Z"
            />
            <path
              className="cls-1"
              d="M205.51,27a2.82,2.82,0,0,1,.2-.68c.17-.38.45-.71.62-1.09a3.13,3.13,0,0,0,.39-1.26,5.89,5.89,0,0,1,.76-2.55c.29-.63.54-1.28.84-1.91.17-.37.4-.73.58-1.1a25.62,25.62,0,0,1,3.41-4.71c.75-.93,1.58-1.81,2.38-2.71a4.76,4.76,0,0,1,.56-.54,1.07,1.07,0,0,1,1.72.3.71.71,0,0,1-.11.67,28.78,28.78,0,0,1-1.92,2.24c-1.07,1.08-2,2.21-3,3.37a20.39,20.39,0,0,0-1.81,2.72,8.88,8.88,0,0,0-1.25,3.56,7,7,0,0,1-.78,2.8c-.18.32-.24.69-.39,1a4.84,4.84,0,0,1-.48.86.74.74,0,0,1-.89.17A1.27,1.27,0,0,1,205.51,27Z"
            />
          </g>
          <path
            id="Seed_5-2"
            data-name="Seed 5"
            className="cls-1"
            d="M410.09,61.93l-1.21-1.38a10.47,10.47,0,0,0,.59-3.39c-.11-1.08.54-2.21.44-3.36a3,3,0,0,1,.13-1.2c.52-1.54,1.18-3,1.61-4.6a37.65,37.65,0,0,1,2.64-5.79,36.87,36.87,0,0,1,4.84-7.8,5.21,5.21,0,0,0,1.09-1.76c.31-1.07,1.31-1.47,2.26-1.91a2.34,2.34,0,0,1,1.15,0,10,10,0,0,1,3.5,1.62,36.81,36.81,0,0,1,3.42,2.48,1.58,1.58,0,0,1,.74,1.8,23.45,23.45,0,0,1-1,3.28c-.92,2-2,3.91-3,5.89-1.52,3.16-4,5.72-6.75,8.17-.7.64-1.36,1.3-2,2a7,7,0,0,1-.8.74,15.26,15.26,0,0,0-3.56,3.47A4.21,4.21,0,0,1,410.09,61.93ZM414,55.21a14.77,14.77,0,0,1,1.78-1.82,35.24,35.24,0,0,0,5.94-6,6.46,6.46,0,0,0,1.3-1.67c.68-1.77,2.05-3.33,2.31-5.24.05-.37.4-.71.63-1.06A4.94,4.94,0,0,0,427,36c-.18-.12-.36-.25-.55-.36-.63-.35-1-1.2-2-.91a10.32,10.32,0,0,0-2.16,1.18c-.18.1-.27.32-.39.49a46.46,46.46,0,0,0-4.78,7.81c-.27.55-.48,1.13-.79,1.67a29.15,29.15,0,0,0-2.6,6.86,5,5,0,0,0-.21,1.38C413.54,54.42,413.78,54.71,414,55.21Z"
          />
          <path
            id="Seed_5-3"
            data-name="Seed 5"
            className="cls-1"
            d="M297.09,74.93l-1.21-1.38a10.47,10.47,0,0,0,.59-3.39c-.11-1.08.54-2.21.44-3.36a3,3,0,0,1,.13-1.2c.52-1.54,1.18-3,1.61-4.6a37.65,37.65,0,0,1,2.64-5.79,36.87,36.87,0,0,1,4.84-7.8,5.21,5.21,0,0,0,1.09-1.76c.31-1.07,1.31-1.47,2.26-1.91a2.34,2.34,0,0,1,1.15,0,10,10,0,0,1,3.5,1.62,36.81,36.81,0,0,1,3.42,2.48,1.58,1.58,0,0,1,.74,1.8,23.45,23.45,0,0,1-1,3.28c-.92,2-2,3.91-3,5.89-1.52,3.16-4,5.72-6.75,8.17-.7.64-1.36,1.3-2,2a7,7,0,0,1-.8.74,15.26,15.26,0,0,0-3.56,3.47A4.21,4.21,0,0,1,297.09,74.93ZM301,68.21a14.77,14.77,0,0,1,1.78-1.82,35.24,35.24,0,0,0,5.94-6,6.46,6.46,0,0,0,1.3-1.67c.68-1.77,2.05-3.33,2.31-5.24.05-.37.4-.71.63-1.06A4.94,4.94,0,0,0,314,49c-.18-.12-.36-.25-.55-.36-.63-.35-1-1.2-2-.91a10.32,10.32,0,0,0-2.16,1.18c-.18.1-.27.32-.39.49a46.46,46.46,0,0,0-4.78,7.81c-.27.55-.48,1.13-.79,1.67a29.15,29.15,0,0,0-2.6,6.86,5,5,0,0,0-.21,1.38C300.54,67.42,300.78,67.71,301,68.21Z"
          />
          <path
            id="Seed_5-4"
            data-name="Seed 5"
            className="cls-1"
            d="M597.09,102.93l-1.21-1.38a10.47,10.47,0,0,0,.59-3.39c-.11-1.08.54-2.21.44-3.36a3,3,0,0,1,.13-1.2c.52-1.54,1.18-3,1.61-4.6a37.65,37.65,0,0,1,2.64-5.79,36.87,36.87,0,0,1,4.84-7.8,5.21,5.21,0,0,0,1.09-1.76c.31-1.07,1.31-1.47,2.26-1.91a2.34,2.34,0,0,1,1.15,0,10,10,0,0,1,3.5,1.62,36.81,36.81,0,0,1,3.42,2.48,1.58,1.58,0,0,1,.74,1.8,23.45,23.45,0,0,1-1,3.28c-.92,2-2,3.91-3,5.89-1.52,3.16-4,5.72-6.75,8.17-.7.64-1.36,1.3-2,2a7,7,0,0,1-.8.74,15.26,15.26,0,0,0-3.56,3.47A4.21,4.21,0,0,1,597.09,102.93ZM601,96.21a14.77,14.77,0,0,1,1.78-1.82,35.24,35.24,0,0,0,5.94-6,6.46,6.46,0,0,0,1.3-1.67c.68-1.77,2.05-3.33,2.31-5.24,0-.37.4-.71.63-1.06A4.94,4.94,0,0,0,614,77c-.18-.12-.36-.25-.55-.36-.63-.35-1-1.2-2-.91a10.32,10.32,0,0,0-2.16,1.18c-.18.1-.27.32-.39.49a46.46,46.46,0,0,0-4.78,7.81c-.27.55-.48,1.13-.79,1.67a29.15,29.15,0,0,0-2.6,6.86,5,5,0,0,0-.21,1.38C600.54,95.42,600.78,95.71,601,96.21Z"
          />
          <g id="Seed_3-3" data-name="Seed 3" className="cls-2">
            <path
              className="cls-3"
              d="M0,88.71A10.19,10.19,0,0,1,1.59,83.3a19.69,19.69,0,0,0,1.2-2.6,14,14,0,0,1,2.3-3.93A5.33,5.33,0,0,0,6,75.59a29.54,29.54,0,0,1,3.1-5.19,20.6,20.6,0,0,1,1.76-2.12,36.42,36.42,0,0,0,2.68-3.53,45.27,45.27,0,0,0,2.61-3.82c1.46-2.56,3.8-4.58,5.77-6.82a18.73,18.73,0,0,0,1.82-2.57,5.72,5.72,0,0,1,1.53-1.49c2.4-1.84,5.46-2.36,8.39-3.15,1.35-.36,2.77-.58,4.16-.82a7.37,7.37,0,0,1,4.18.4c.76.31,1.66.38,2.47.64a51,51,0,0,1,5.27,1.8,7.08,7.08,0,0,1,3.06,3.26c1.33,2.28,2.64,4.59,2.71,7.21,1,2.76,0,5.27-1.26,7.78a23.57,23.57,0,0,1-4.53,6.56A11.73,11.73,0,0,1,46.42,76a42.67,42.67,0,0,1-11.09,3.48,31.08,31.08,0,0,0-5.44,1.43c-3,1.06-6.13,1.81-9.23,2.64A43,43,0,0,0,13,86.09c-2,1-4,1.9-5.87,3a32.86,32.86,0,0,1-3.83,1.59,3.25,3.25,0,0,1-1.59.11C.58,90.64.18,90.21.05,89.31,0,89.11,0,88.91,0,88.71Zm4.33-2.66a3.62,3.62,0,0,0,1,0c1.82-.66,3.69-1.24,5.42-2a39.58,39.58,0,0,1,7.47-2.53c1.29-.32,2.56-.69,3.84-1q2.37-.65,4.76-1.26c1.51-.4,3-.77,4.54-1.17,1.05-.27,2.1-.58,3.16-.86s2.28-.55,3.4-.88c1.87-.55,3.46-1.65,5.47-1.92a1.14,1.14,0,0,0,.42-.19,22.8,22.8,0,0,0,5.31-4.56,26.67,26.67,0,0,0,2.67-6.42c0-.19,0-.4.08-.59.65-3.91-1.08-7.24-3.57-10.36A4.72,4.72,0,0,0,46.52,51a38.39,38.39,0,0,0-3.76-1.22c-.67-.19-1.4-.27-2-.5a7,7,0,0,0-5.28.2,28.22,28.22,0,0,1-3.56,1.09A10.73,10.73,0,0,0,25.92,54a18.45,18.45,0,0,1-3.71,3.9,5.65,5.65,0,0,0-1.05,1.08,27.33,27.33,0,0,0-4,6.36,6.25,6.25,0,0,1-.67,1.28c-1.1,1.38-2.26,2.73-3.37,4.11-.93,1.16-1.91,2.31-2.72,3.53Q7.89,78,5.59,81.85A9.55,9.55,0,0,0,4.34,86.05Z"
            />
            <path
              className="cls-3"
              d="M19.21,68.62a4.29,4.29,0,0,1-1-.47c-.46-.41-.68-1-.24-1.46A12.23,12.23,0,0,1,20.75,64,64.34,64.34,0,0,0,27,59.71a24.49,24.49,0,0,1,3.4-1.8,1.06,1.06,0,0,1,.84.14.74.74,0,0,1,.19.72,8.86,8.86,0,0,1-2.93,3.33c-1.68,1.1-3.38,2.2-5,3.39-1.17.88-2.67,1.45-3.48,2.67C19.91,68.36,19.54,68.44,19.21,68.62Z"
            />
            <path
              className="cls-3"
              d="M36.79,67c-.28.42-.4.88-.74,1.08-1.29.74-2.32,1.82-3.85,2.28a2.91,2.91,0,0,0-.8.42c-2.1,1.46-4.66,2.22-7,3.38a2.93,2.93,0,0,1-.64.25A1.58,1.58,0,0,1,22.3,74a1,1,0,0,1-.16-1.47,7.34,7.34,0,0,1,3.5-2.28q2.94-1.08,5.83-2.2c.86-.34,1.65-.81,2.51-1.12a9.15,9.15,0,0,1,2-.42C36.22,66.53,36.48,66.8,36.79,67Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}