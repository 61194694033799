import React from "react";
import { isMobile } from 'react-device-detect';
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { LeafIcon, WormIcon, ForkIcon, SeedsIcon, SoilIcon } from "../components/svg";
import { HomeTile } from "../components/sections";
import { Button } from "../components/buttons";
import HomeGallery from "../components/gallery";

export default function HomePage() {
  return (
    <div>
      {isMobile ? (
        <ParallaxBanner style={{ aspectRatio: '1 / 2' }}>
          <ParallaxBannerLayer image="https://cdn.vagabondvege.nz/images/pic/MAIN.jpg" speed={15} />
          <ParallaxBannerLayer>
            <div className="absolute top-[90px] left-[20px] right-[20px] text-center">
              <h1 className="font-serif text-4xl leading-normal text-white-400">Vagabond Vege is a human scale, collaborative market garden on the old river terraces of the Waiohine River</h1>
            </div>
            
          </ParallaxBannerLayer>
        </ParallaxBanner>
      ) : (
        <ParallaxBanner style={{ aspectRatio: '3 / 1' }}>
          <ParallaxBannerLayer image="https://cdn.vagabondvege.nz/images/pic/MAIN.jpg" speed={15} />
          <ParallaxBannerLayer>
            <div className="absolute top-[160px] left-[80px] right-[80px] text-center">
              <h1 className="font-serif text-5xl leading-normal text-white-400">Vagabond Vege is a human scale, collaborative market garden on the old river terraces of the Waiohine River</h1>
            </div>
            
          </ParallaxBannerLayer>
        </ParallaxBanner>
      )}

      <HomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/CSA.jpg"
      >
        <LeafIcon width="279" rotate="90" left="-60" bottom="-130" />
        <WormIcon width="157" rotate="-76" right="-77" top="-74" />
        <h2
          class="text-white-400 text-5xl md:text-[4.5rem] mb-10 leading-none font-serif"
        >
          GET OUR VEGE
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Our vege is avaliable as a part of the Little Farms seasonal boxes
        </p>
        <Button
          link="https://wearelittlefarms.com/collections/farm-box-weekly-subscriptions"
          title="Get them here"
          color="white"
        />
      </HomeTile>

      {/* <HomeTile
        color="olive"
        image="https://cdn.vagabondvege.nz/images/pic/WORKSHOP.jpg"
      >
        <ForkIcon width="279" rotate="90" left="-60" bottom="-130" />
        <h2
          class="text-white-400 text-5xl md:text-[4.5rem] mb-10 leading-none font-serif"
        >
          ON FARM<br/>WORKSHOPS
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Join us on the farm for hands on learning around composting, sowing, soil and gardening.
        </p>
        <Button
          link="/workshops"
          title="Learn More"
          color="white"
        />
      </HomeTile> */}

      <HomeTile
        color="mustard"
        image="https://cdn.vagabondvege.nz/images/pic/PRACTICE.jpg"
        outerIcon={(<SeedsIcon width="calc(100% - 20px)" left="10" bottom="10" />)}
      >
        <h2
          class="text-white-400 text-5xl md:text-[4.5rem] mb-10 leading-none font-serif"
        >
          OUR<br/>PRACTICES
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          We use agroecological principles to farm the land and produce high quality, nutrient dense food.
        </p>
        <Button
          link="/our-practice"
          title="Learn More"
          color="white"
        />
      </HomeTile>

      <HomeTile
        color="olive"
        image="https://cdn.vagabondvege.nz/images/pic/SALAD.jpg"
      >
        <ForkIcon width="279" rotate="90" left="-60" bottom="-130" />
        <h2
          class="text-white-400 text-5xl md:text-[4.5rem] mb-10 leading-none font-serif"
        >
          WHOLESALE<br/>ORDERS
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          We offer wholesale ordering via a weekly freshlist
        </p>
        <Button
          link="/wholesale"
          title="Learn More"
          color="white"
        />
      </HomeTile>

      <HomeGallery />
      
      <section className="relative z-20 m-0 overflow-hidden py-[30px] md:py-[70px] px-[0px] bg-brown-400">
        <div class="container mx-auto">
          <h3 class="text-white-400 text-center font-serif text-3xl" data-rellax-speed="-1" data-rellax-percentage="0.5">SERVED<br/>AT</h3>
          <div className="grid grid-cols-1 md:flex gap-10 items-center justify-center justify-items-center my-10 mb-16">
            <div className="rita">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 100, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/rita.png"
                alt="rita resturant logo"
              />
            </div>
            <div className="swan">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 100, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/white_swan.png"
                alt="white swan resturant logo"
              />
            </div>
          </div>
          <h3 class="text-white-400 text-center font-serif text-3xl" data-rellax-speed="-1" data-rellax-percentage="0.5">FEATURED<br/>IN</h3>

          <div className="grid grid-cols-1 md:flex gap-10 items-center justify-center justify-items-center my-10 mb-0">
            <div className="organic">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 80, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/organic.webp"
                alt="organic nz logo"
              />
            </div>
            <div className="cuisine">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 80, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/cuisine.png"
                alt="cuisine magazine logo"
              />
            </div>
            <div className="capital">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 80, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/capital.png"
                alt="capital magazine logo"
              />
            </div>
            <div className="nzlife">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 80, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/thisnzlife.avif"
                alt="this nz life magazine logo"
              />
            </div>
            <div className="times">
              <img
                className="b-lazy snug"
                style={{ maxHeight: 80, width: "auto" }}
                src="https://cdn.vagabondvege.nz/images/pic/wairarapa_age.png"
                alt="Wairarapa times age newpapers logo"
              />
            </div>
          </div>
        </div>
      </section>

      <HomeTile
        color="olive"
        image="https://cdn.vagabondvege.nz/images/pic/CAB.jpg"
        outerIcon={(<SoilIcon width="calc(100%)" left="0" bottom="0" />)}
      >
        <h2
          class="text-white-400 text-5xl md:text-[4.5rem] mb-10 leading-none font-serif"
        >
          SUPPORT LOCAL<br/>GROWERS
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Back the farm and secure your vege through Little Farms
        </p>
        <Button
          link="https://wearelittlefarms.com/collections/farm-box-weekly-subscriptions"
          title="Get a seasonal box"
          color="white"
        />
      </HomeTile>

      <section 
        className="bg-white-400 relative px-0 py-4 md:py-16"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 w-[90%] md:w-[80%] max-w-[960px] mx-auto gap-20">
          <div className="pt-4 md:py-11">
            <h1 className="font-serif text-3xl mb-5">Contact Us</h1>
            <p className="leading-8 text-xl mb-5">
              If you want to get in touch with the farm crew at Vagabond Vege, please send
              us an email at{" "}
              <a href="mailto:kiaora@vagabondvege.nz">kiaora@vagabondvege.nz</a>
            </p>
            <p className="leading-8 text-xl mb-5">Or phone Saskia: 022 0859 745</p>
            <p className="leading-8 text-xl md:mb-5">Te Hūpenui/Greytown, Wairarapa, Aotearoa</p>
          </div>
          <div>
            <div>
              <img src="https://cdn.vagabondvege.nz/images/pic/team.jpg" loading="lazy" alt="Vagabond vege team" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
