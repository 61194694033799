import React from "react";
import { isMobile } from "react-device-detect";
import { Parallax } from "react-scroll-parallax";

function HomeTile(props) {
  const c = {
    olive : "#8e9d6c",
    mustard : "#ff9500",
    brown : "#cb9969",
    cream : "#E5D5AF",
    white : "#f7e6ca"
  }
  const bg = {background : c[props.color]};
  let tileClass = `relative z-20 m-0 overflow-hidden py-[70px] px-[70px]`;
  if (isMobile) {
    tileClass = `relative z-20 m-0 overflow-hidden py-[30px] px-[30px]`;
  }
  

  return (
    <div 
      className={tileClass}
      style={bg}
    >
      {props.outerIcon} 
      <div className="flex flex-row flex-wrap content-center relative z-20">
        <Parallax speed={4} className="relative self-center text-center flex-1 basis-[50%] max-w-full">
          {props.children}
        </Parallax>
        <Parallax speed={-6} class="self-center text-center flex-1 basis-[50%]">
          <img
            className="w-full md:w-[80%] mx-auto rounded-2xl shadow-tile-image"
            src={props.image} 
            alt=""
          />
        </Parallax>
      </div>
    </div>
  )
}

function SmallHomeTile(props) {
  const c = {
    olive : "#8e9d6c",
    mustard : "#ff9500",
    brown : "#cb9969",
    cream : "#E5D5AF",
    white : "#f7e6ca"
  }
  const bg = {background : c[props.color]};
  const tileClass = `relative z-20 m-0 overflow-hidden py-[70px] px-[70px]`;

  return (
    <div 
      className={tileClass}
      style={bg}
    >
      {props.outerIcon} 
      <div className="flex flex-row flex-wrap content-center relative z-20">
        <Parallax speed={4} className="relative self-center text-center flex-1 basis-[60%] max-w-full">
          {props.children}
        </Parallax>
        <Parallax speed={-6} class="self-center text-center flex-1 basis-[40%]">
          <img
            className="w-[75%] mx-auto rounded-2xl shadow-tile-image"
            src={props.image} 
            alt=""
          />
        </Parallax>
      </div>
    </div>
  )
}

export { HomeTile, SmallHomeTile };
