import React from "react";

function Button(props) {
  const c = {
    olive : "#8e9d6c",
    mustard : "#ff9500",
    brown : "#cb9969",
    cream : "#E5D5AF",
    white : {
      borderColor: "#f5e8ce",
      background: "#f5e8ce",
      color: "#85975a"
    }
  }
  return (
    <a
      className="inline-block mx-auto h-[60px] text-center font-semibold font-sans text-sm uppercase rounded leading-[61px] px-8 tracking-wider"
      href={props.link}
      style={c[props.color]}
      target={props.target || "_self"}
    >
      {props.title}
    </a>
  )
}

export { Button };
