import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "https://cdn.vagabondvege.nz/images/pic/ELSASK.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/ELSASK.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/CAB.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/CAB.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/CARROT.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/CARROT.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/TOMS.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/TOMS.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/GROUP.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/GROUP.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/ONIONS.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/ONIONS.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/CSA.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/CSA.jpg",
  },
  {
    original: "https://cdn.vagabondvege.nz/images/pic/CHILLI.jpg",
    thumbnail: "https://cdn.vagabondvege.nz/images/pic/CHILLI.jpg",
  },
];

export default function HomeGallery() {
  return (
    <div className="relative z-20 m-0 overflow-hidden py-[70px] px-[20px]">
      <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} showThumbnails={false} />
    </div>
  );
}
