import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { Navbar, Footer } from "./components/navigation/navBar";
import { ParallaxProvider } from "react-scroll-parallax";

import HomePage from "./pages/home";
import CSAPage from "./pages/csa";
import WorkshopPage from "./pages/workshops";
import AboutPage from "./pages/about";
import PracticePage from "./pages/practice";
import WholesalePage from "./pages/wholesale";

function Layout() {

  return (
    <div className="relative w-full p-0 m-0">
      <div className="relative w-full overflow-hidden">
        <Navbar />
      </div>
      <div className="relative w-full z-0">
        <Outlet />
      </div>
      <div className="relative w-full overflow-hidden">
        <Footer />
      </div>
    </div>
  )
}

const App = () => {
  return (
    <>
      <ParallaxProvider>
        <Routes>
          <Route path="/*" element={<Layout />}>
            <Route path="" element={<HomePage />} title="Vagabond Vege | Wairarapa Aoteoroa"></Route>
            <Route path="csa" element={<CSAPage />} title="Vagabond Vege | CSA"></Route>
            <Route path="workshops" element={<WorkshopPage />} title="Vagabond Vege | WORKSHOPS"></Route>
            <Route path="about" element={<AboutPage />} title="Vagabond Vege | ABOUT US"></Route>
            <Route path="our-practice" element={<PracticePage />} title="Vagabond Vege | OUR PRACTICES"></Route>
            <Route path="wholesale" element={<WholesalePage />} title="Vagabond Vege | WHOLESALE"></Route>
          </Route>
        </Routes>
      </ParallaxProvider>
    </>
  );
};

export default App;
