import React from "react";
import { HomeTile } from "../components/sections";
import { Button } from "../components/buttons";

export default function PracticePage() {
  return (
    <>
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <h4 className="font-serif text-olive-400 text-3xl leading-normal">
            We all know that we need to move away from industrialised monocrop and
            chemical dependent food systems. At Vagabond Vege, we look at
            agriculture through a different lens. We believe that through
            collaborative networks of local small scale farms, who have diversity
            and ecosystem health as a cornerstone of their practice, we can create a
            more resilient food system.
          </h4>
        </div>
      </div>
      <section className="image-cover onions">
        <div className="message">
          <h1 className="font-serif">Human scale farming that sees life flourish</h1>
        </div>
      </section>

      <HomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/hua_parakore.jpg"
      >
        <h2
          class="text-white-400 text-[4.5rem] mb-10 leading-none font-serif"
        >
          HUA PARAKORE
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          We are currently working through a three year process to become Hua
          Parakore certified grower. This is a holistic kaupapa Māori framework
          for certifing Kai Atua - pure foods free from GMO's, pesticides and
          synthetic fertilizers.
        </p>
        <Button
          link="https://www.tewakakaiora.co.nz/whatishuaparakore"
          target="_blank"
          title="Learn More"
          color="white"
        />
      </HomeTile>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <h4 className="font-serif text-olive-400 text-3xl mb-8">Why Regenerative?</h4>
          <p className="text-xl text-left leading-normal mb-5">
            To us, regenerative means farming in a way that improves the resources
            it uses, rather than destroying or depleting them. This is not a
            textbook process, but rather a practice rooted in our context and
            conditions. Ecosystems are an evolving spiral, requiring continuous
            learning and observation by the grower to contribute positively to its
            growth.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            Healthy soil full of life is the starting point for growing healthy
            produce. Regeneratively managed landscapes can act as carbon sinks and
            retain more rainwater by restoring the natural cycles of carbon, water,
            and nitrogen.
          </p>
          <h4 className="font-serif text-olive-400 text-3xl mb-8">
            Healthy soil = healthy produce = healthy people
          </h4>
          <p className="text-xl text-left leading-normal mb-5">For us, this looks like:</p>
          <ol className="text-left px-6 pb-5 text-lg list-decimal">
            <li className="mb-3">
              No-till horticulture, causing minimal disturbance to the soil so life
              can thrive
            </li>
            <li className="mb-3">
              The removal of chemical and synthetic inputs to allow the natural
              fertilisers of soil (microbes and worms) to form relationships with
              plant roots
            </li>
            <li>
              Dense and diverse plantings to enhance beneficial fungi, bacteria and
              insect life in our soils
            </li>
          </ol>
          <p className="text-xl text-left leading-normal mb-5">
            Farming in a changing climate, where droughts and larger storm events
            will occur more frequently, these practices are more important than ever
            to create resilience.
          </p>
        </div>
      </div>

      <section className="image-cover narrow toms">
        <div className="message">
          <h1 className="font-serif">Diversity</h1>
        </div>
      </section>

      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <p className="text-xl text-left leading-normal mb-5">
            The soil is alive. Teeming with billions of microbes and insects that
            build and maintain the soil ecosystem. They need constant feeding, which
            is the role of the plant roots. By providing a diverse set of plant
            roots year round we can build and maintain a robust population of
            micro-organisms. We try to have multi species growing at once in each
            bed and rotate species between the beds during the season.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            By feeding these tiny team members we are not only looking after our
            precious soil resource, but also helping the plants recieve more
            nutrients in return for feeding them. Over the coming seasons we hope to
            see stronger, healthier and more vibrant plants growing in the fields,
            ready to feed our community for seasons to come.
          </p>
        </div>
      </div>

      <section className="image-cover narrow seed">
        <div className="message">
          <h1 className="font-serif">Seed Sourcing</h1>
        </div>
      </section>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <p className="text-xl text-left leading-normal mb-5">
            We are currently sourcing most of the kākano for our maara kai from
            large seed producers. With the experience we have had in other market
            gardens we have been following standard practice sourcing untreated seed
            from producers of various scales. This includes international producers.
            Whilst all of this seed is untreated, we feel that sourcing from large
            scale seed producers over the long term does not align with our kaupapa.
            This choice has felt like a necessity in order to operate. We intend to
            work on the access we (and other growers) have to geographically
            connected and resilient seed.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            We know we have many collaborations to form to begin to build a seed
            network that works economically for growers, that aligns with our
            kaupapa and that adapts alongside the whenua. We intend toward the
            practise of more localised seed use, saving and sharing in our maara
            kai.
          </p>
        </div>
      </div>

      <section className="image-cover narrow group">
        <div className="message">
          <h1 className="font-serif">Collaboration</h1>
        </div>
      </section>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <h4 className="font-serif text-olive-400 text-3xl mb-8">Why Collaborative?</h4>
          <p className="text-xl text-left leading-normal mb-5">
            There are three main reasons why we have decided to farm in a collective
            manner:
          </p>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">Resources</h5>
          <p className="text-xl text-left leading-normal mb-5">
            The main barriers to beginning a farm are generally access to land,
            finance and expertise. By coming together under a shared vision we have
            been able to significantly reduce those barriers and made it possible to
            start farming and producing food at scale.
          </p>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">Sustainability</h5>
          <p className="text-xl text-left leading-normal mb-5">
            We all love being outside, working with plants and animals, and doing
            what is necessary to produce food. However, we realise that having the
            ability to leave the farm, take holidays and make commitments to friends
            and family is important for a more balanced and fulfilling life. As an
            owner-worker collective we have the ability to provide each other with
            time off - something that most farmers are not fortunate enough to have.
          </p>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">Longevity</h5>
          <p className="text-xl text-left leading-normal mb-5">
            We don't believe competition results in the best outcomes for
            businesses, particularly for small food producers. Along with our
            internal collaboration, we believe that through collaborating with other
            growers and producers we can form mutually benefical relationships that
            can support and enhance each other's work.
          </p>
        </div>
      </div>
    </>
  )
}