import React, {useState} from "react";
import { Bars3Icon } from '@heroicons/react/24/outline';
import { NavLink, useLocation } from "react-router-dom";

function Link(name, path, currentPath, click) {
  const selected = path === currentPath;
  const className = `hover:underline hover:text-brown-600 ${selected ? "text-brown-600 underline" : "text-brown-400"}`;
  return (
    <NavLink to={path} className={className} onClick={click}>{name}</NavLink>
  )
} 

export default function Navbar() {
  const path = useLocation().pathname;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <header className="flex flex-col gap-10 flex-nowrap justify-center items-center px-0 font-serif my-0 py-4 bg-cream-400">
        <div className="flex-0 self-auto">
          <img 
            className="max-w-[100px] md:max-w-[165px] my-0 mx-auto block relative"
            src="https://cdn.vagabondvege.nz/images/logo/logo_main.png" 
            alt="vagabond vege logo. Linocut vegetables in olive." 
          />
        </div>
        <div className="flex-0 self-auto">
          <div className="hidden md:flex gap-10">
            {Link('Home', '/', path)}
            {Link('Our Practice', '/our-practice', path)}
            {Link('About', '/about', path)}
            {Link('Wholesale', '/wholesale', path)}
          </div>
          <div className="flex md:hidden justify-end pr-8 align-middle">
            <button 
              className="px-1 py-1 rounded-sm border border-transparent hover:bg-[rgba(50,50,50,0.2)] hover:border-slate-400"
              onClick={e => setMenuOpen(true)}
            >
              <Bars3Icon width="30" height="30"></Bars3Icon>
            </button>
          </div>
        </div>
      </header>
      <Menu
        path={path}
        closeMenu={e => setMenuOpen(false)}
        open={menuOpen}
      />
    </>
  );
}

function Menu(props) {
  const {path, closeMenu, open} = props;

  return (
    <div className={`${open ? "fixed" : "hidden"} font-serif inset-0 bg-slate-800 z-50`}>
      <div className="px-10 py-6">
        <div className="grid grid-cols-1 gap-8 text-4xl" onClick={closeMenu}>
          {Link('Home', '/', path, closeMenu)}
          {Link('Our Practice', '/our-practice', path)}
          {Link('About', '/about', path)}
          {Link('Wholesale', '/wholesale', path)}
        </div>
      </div>
    </div>
  )
}

function Footer() {
  const path = useLocation().pathname;

  return (
    <footer className="flex flex-row flex-wrap md:flex-nowrap justify-around items-center place-content-stretch px-6 font-serif my-0 py-6 bg-cream-400">
      <div className="flex-1 basis-[30%] self-auto">
        <img 
          className="max-w-[60px] my-0 mx-auto block relative"
          src="https://cdn.vagabondvege.nz/images/logo/logo_main.png" 
          alt="vagabond vege logo. Linocut vegetables in olive." 
        />
      </div>
      <div className="flex-1 basis-[70%] md:basis-[40%] self-auto">
        <div className="text-center opacity-80">
          <h1 className="font-serif text-[#081c21]">Vagabond Vege<br/>Monty's Lane, Te Hupenui, Greytown</h1>
        </div>
      </div>
      
      <div className="flex-1 basis-[100%] md:basis-[30%] self-auto">
        <div className="flex gap-10 px-4 text-center content-around justify-center">
          {Link('Home', '/', path)}
          {Link('About', '/about', path)}
        </div>
      </div>
    </footer>
  );
}
  

export { Navbar, Footer };