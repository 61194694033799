import React from "react";
import { ForkIcon } from "../components/svg";
import { HomeTile } from "../components/sections";

export default function AboutPage() {
  return (
    <div>
      <section className="image-cover group">
        <div className="message">
          <h1 className="font-serif">Meet the Vagabonds</h1>
        </div>
      </section>

      <HomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/ELLE.jpg"
      >
        <ForkIcon width="279" rotate="90" left="-60" bottom="-130" />
        <h2
          className="text-white-400 text-[4.5rem] mb-6 leading-none font-serif"
        >
          ELLE FARR
        </h2>
        <p
          className="text-white-400 text-md italic mb-6"
        >
          Founder, She/her | Nursery Manager, Integrated Pest Management
        </p>
        <p
          className="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Born in Northland, between the white sand east coast and the mudflats of the Kaipara Harbour, Elle descends from Scotland, Wales, Denmark and Romania. Elle found her way to farming through the care for all things small (insects!), a deep curiosity for how everyone relates, and a determination to be outside doing something with great purpose to care for this whenua and the creatures calling her home.
          Elle can be found sowing seeds in the nursery or nestled in the soil asking questions.
        </p>
      </HomeTile>

      <HomeTile
        color="mustard"
        image="https://cdn.vagabondvege.nz/images/pic/WORKSHOP.jpg"
      >
        <ForkIcon width="279" rotate="90" left="-60" bottom="-130" />
        <h2
          className="text-white-400 text-[4.5rem] mb-6 leading-none font-serif"
        >
          SHELDON LEVET
        </h2>
        <p
          className="text-white-400 text-md italic mb-6"
        >
          Founder, He/her | Production Manager, Integrated Nutrient Management, Compost
        </p>
        <p
          className="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          I grew up in Ahuriri (Napier) a costal town in the sunny Hawkes Bay
          - but my ancestors hail from Europe, mostly Ireland. A curious child
          (and adult) who was always tinkering with something in the shed or learning something new and weird. This curiosity hasn’t left, I still love
          wondering about things, scheming new ideas late into the night and
          getting overly excited about the next project. I love low-land forest paths by rivers,
          piles of mulch, old bread, compost heaps… anywhere I might find some
          fungi!!!
        </p>
      </HomeTile>

      <HomeTile
        color="olive"
        image="https://cdn.vagabondvege.nz/images/pic/SASKIA.jpg"
      >
        <ForkIcon width="279" rotate="90" left="-60" bottom="-130" />
        <h2
          className="text-white-400 text-[4.5rem] mb-6 leading-none font-serif"
        >
          SASKIA WANKLYN
        </h2>
        <p
          className="text-white-400 text-md italic mb-6"
        >
          Founder, She/her | Business, Customer & Sales Manager
        </p>
        <p
          className="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Sask’s whakapapa is to England, Scotland & Wales and has a history of many farmers and gardeners in her ancestry. She has a love for being in relationship with the many living beings, possibly spurred on by walks in her childhood with her Grandma through the ngāhere of the Marlborough Sounds. This love, coupled with the discovery of community gardening, lead her to farming vege.
        </p>
      </HomeTile>

      <HomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/LISE.jpg"
      >
        <ForkIcon width="279" rotate="90" left="-60" bottom="-130" />
        <h2
          className="text-white-400 text-[4.5rem] mb-6 leading-none font-serif"
        >
          LISE VAN LAERE
        </h2>
        <p
          className="text-white-400 text-md italic mb-6"
        >
          Founder, He/her | Aspiring Luthier, Biggest Supporter
        </p>
        <p
          className="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Kia ora/ Goeie morgen! I’m Belgian (speculoos baby), grew up in a
          small town near Brussels in Flanders, eating vegetables my grandad
          (still!) grows in his beautiful back yard garden. I did a degree in
          Slavic studies (Polish &amp; Russian), and came to Aotearoa 4 years
          ago to study environmental policy. Here I met an amazing community
          and my partner, Sheldon. I’m an aspiring luthier (stringed
          instrument repairs), and have been an apprentice for the last two
          years 🎻 I love long tramps, night skies and autumn colours.
        </p>
      </HomeTile>

      <section className="image-cover whenua narrow">
        <div className="message">
          <h1 className="font-serif">The Story of the Whenua</h1>
        </div>
      </section>

      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <p className="text-xl text-left leading-normal mb-5">
            <i>
              The history is far from complete, we will continue to update as we
              learn more. We would greatly appreciate any knowledge you would like
              to share.
            </i>
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            This land is in the rohe (area) of Ngāti Kahungunu and Rangitāne o
            Wairarapa.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            By 1600, Rangitāne and then Ngāti Kahungunu had settled in the
            Wairarapa. Although periods of conflict saw disputes between the two iwi
            and surrounding iwi groups, on the whole, they have coexisted peacefully
            in the region.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            We are grateful to both iwi for the resources they have provided on
            their websites, which has allowed us to begin our journey. We intend to
            honour Te Tiriti o Waitangi in the work that we do.
          </p>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">Land Possession</h5>
          <p className="text-xl text-left leading-normal mb-5">
            Te Hupenui is one of the old names of the area near where the town known
            as Greytown is located today. Greytown was established after land was
            said to have been bought by the Small Farms Association with the support
            of Governor George Grey in 1853. The Small Farms Association was formed
            in 1853 as the demand from working class settlers for farming land grew.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            The land we are working with today lies just west of Te Hupenui and was
            part of a large farming lot known as ‘Riverside Estate’ resting on old
            river terraces of the Waiohine river, below the Tararua Ranges.
          </p>
          <div className="mb-5">
            <img
              className="b-lazy"
              src="https://cdn.vagabondvege.nz/images/pic/monties.png"
              alt="The cottage as it was around 1900, with sheep grazing in fields which is now the driveway. Credit: Montgomerie Family."
            />
            <small className="caption">
              The cottage as it was around 1900, with sheep grazing in fields which
              is now the driveway. Credit: Montgomerie Family.
            </small>
          </div>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">The Cotters</h5>
          <p className="text-xl text-left leading-normal mb-5">
            Riverside Estate was owned by Pierce Cotter – this included
            ‘Cotterville’ which was established to house farm workers (today this is
            Cotter &amp; Pierce Streets, Greytown). It also includes the land to the
            west towards Woodside and south towards Tauherenikau and Moroa. Along
            with shearing sheep, the story goes that the woolshed on the property
            once was working as a flax mill, used by the Cotter's who unsuccessfully
            attempted to grow and mill flax on the land.
          </p>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">The Montgomeries</h5>
          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="img_wrap">
              <img
                className="b-lazy"
                src="https://cdn.vagabondvege.nz/images/pic/monties_b.png"
                alt="The Montgomerie boy's (Roy, Ted and Tom) in their scout uniforms. In the background is Adam Montgomerie and the cottage. Circa 1912. Credit: Montgomerie Family."
              />
              <small className="caption">
                The Montgomerie boy's (Roy, Ted and Tom) in their scout uniforms.
                In the background is Adam Montgomerie and the cottage. Circa 1912.
                Credit: Montgomerie Family.
              </small>
            </div>
            <div className="">
              <p className="text-xl text-left leading-normal mb-5">
                After early subdivision of this land, the Montgomerie family
                purchased 172 acres which included the land we call home today.
                Harold Montgomerie was very passionate about the native trees on the
                property and the family ensured that various areas of native bush
                were protected by covenants with the Queen Elizabeth II National
                Trust prior to the land being sold in the 1990s.
              </p>
              <p className="text-xl text-left leading-normal mb-5">
                Neil and Greg Montgomerie-Crowe continue to live in the area and we
                are very grateful for the information they have shared with us on
                their ancestors and the history of this land.
              </p>
            </div>
          </div>
          <h5 className="font-serif text-olive-400 text-2xl mb-3 text-left">Today</h5>
          <p className="text-xl text-left leading-normal mb-5">
            The land we farm today is a seven acre plot remaining from the
            subdivision of Riverside Estate. It holds the original farm cottage in
            which we live, the stables and the woolshed that date back to the late
            1800s and early 1900s. Finally, the land was purchased in September 2014
            by Justin Connor who’s keen to see a forever food place where people
            feel grounded, connected and inspired about the future. We are grateful
            for Justin for walking along side us as our friend and land owner.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            Nestled between small groves of Tōtara, Pine and Eucalyptus, this
            stretch of land was once a braided riverbed. In the process of preparing
            the soil to form garden beds, we are removing tonnes of river stones
            from the top soil. We do this with the intention of honouring the
            history and ancestors of this land by helping it flourish into the
            future.
          </p>
        </div>
      </div>
    </div>
  )
}