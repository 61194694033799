import React from "react";

import PhotoAlbum from "react-photo-album";
import { Parallax } from "react-scroll-parallax";

const photos = [
  
  { src: "https://cdn.vagabondvege.nz/images/ws/2.JPG", width: 3024, height: 4032 },
  { src: "https://cdn.vagabondvege.nz/images/ws/13.JPG", width: 3024, height: 4032 },
  { src: "https://cdn.vagabondvege.nz/images/ws/3.JPG", width: 3024, height: 4032 },
  { src: "https://cdn.vagabondvege.nz/images/ws/5.JPG", width: 1869, height: 3247 },
  { src: "https://cdn.vagabondvege.nz/images/ws/6.jpg", width: 3000, height: 4000 },
  { src: "https://cdn.vagabondvege.nz/images/ws/11.jpg", width: 2126, height: 3525 },
  { src: "https://cdn.vagabondvege.nz/images/ws/7.jpg", width: 3000, height: 4000 },
  { src: "https://cdn.vagabondvege.nz/images/ws/4.JPG", width: 4032, height: 3024 },
  { src: "https://cdn.vagabondvege.nz/images/ws/1.JPG", width: 3024, height: 4032 },
  { src: "https://cdn.vagabondvege.nz/images/ws/15.JPG", width: 3020, height: 2182 },
  { src: "https://cdn.vagabondvege.nz/images/ws/8.jpg", width: 3000, height: 4000 },
  { src: "https://cdn.vagabondvege.nz/images/ws/9.JPG", width: 3024, height: 4032 },
  { src: "https://cdn.vagabondvege.nz/images/ws/10.JPG", width: 3024, height: 4032 },
  { src: "https://cdn.vagabondvege.nz/images/ws/12.JPG", width: 1898, height: 3235 },
  { src: "https://cdn.vagabondvege.nz/images/ws/14.JPG", width: 2598, height: 953 }
];

export default function WholesalePage() {
  return (
    <>
      <div className="relative px-6 md:px-0 py-10 md:py-16 pb-8 bg-white-400">
        <div className="max-w-4xl mx-auto text-center">
          <h4 className="font-serif text-olive-400 text-2xl md:text-3xl leading-normal">
            We offer a wide range of high quality, fresh produce wholesale to restaurants
            and caterers across the Wairarapa and central Pōneke. Delivery is available.
            If you are interested in receiving our weekly fresh list, or have any
            questions about wholesale produce, please contact kiaora@vagabondvege.nz or
            call Saskia on 0220859745
          </h4>
        </div>
      </div>

      <div className="relative px-6 md:px-0 py-10 md:py-16 pb-12 bg-mustard-400">
        <div className="max-w-4xl mx-auto text-center relative">
          <h4 className="absolute text-white-400 opacity-70 text-[20rem] font-serif top-[-90px] left-[-75px] leading-none">&ldquo;</h4>
          {/* <h4 className="absolute text-white-400 opacity-70 text-[15rem] font-serif bottom-[-184px] right-[-94px] leading-none">&rdquo;</h4> */}
          <Parallax translateX={[-10, 5]}>
            <h4 className="font-serif text-white-400 text-4xl leading-normal">
              Produce is exceptional, consistently awesome, always graded nicely, I can tell you pay attention to how it would look on a plate and comms are great
            </h4>
          </Parallax>
          <Parallax translateX={[10, -5]}>
            <p className="font-serif text-xl text-right italic opacity-70">Kelda Haines, Rita Resturant, Wellington</p>
          </Parallax>
        </div>
      </div>

      <div className="relative py-16 px-8 bg-white-400">
        <PhotoAlbum layout="columns" photos={photos} />
      </div>
    </>
  )
}