import React from "react";
import { SeedsIcon, SoilIcon, LeafIcon, WormIcon } from "../components/svg";
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { SmallHomeTile } from "../components/sections";

export default function CSAPage() {
  return (
    <div>
      <ParallaxBanner className="hidden md:block" style={{ aspectRatio: '3 / 1' }}>
        <ParallaxBannerLayer image="https://cdn.vagabondvege.nz/images/pic/CSA_HEADER.jpg" speed={20} rootMargin={{top:200, left:0, bottom: 0, right:0}} />
        <ParallaxBannerLayer>
          <div className="absolute top-[160px] left-[80px] right-[80px] text-center">
            <h1 className="font-serif text-5xl leading-normal text-white-400">Vagabond Vege CSA</h1>
          </div>
          
        </ParallaxBannerLayer>
      </ParallaxBanner>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-left">

          <div className="bg-orange-400 rounded text-white-400 mb-14 py-5 px-3 border-8 border-orange-300">
            <h1 className="font-serif text-white-400 text-2xl text-center mb-1">Pōneke subscriptions open</h1>
            <p className="text-white-400 text-lg text-center">We are now accepting CSA members from the Pōneke region, with deliveries on Wednesday evenings - specific areas below.</p>
          </div>

          <h1 className="font-serif text-olive-400 text-4xl text-center mb-5">Vagabond Vege CSA</h1>
          <h2 className="font-serif text-olive-400 text-2xl mb-3">What is a CSA?</h2>
          <p className="text-xl text-left leading-normal mb-5">
            CSA stands for Community Supported Agriculture. It’s a partnership
            between awesome people like you, and small sustainable farms like us,
            that ensures prosperity for both parties. As a CSA member, your
            financial contribution and commitment gives us the stability and
            security to cover the costs of growing. In return you will receive a
            share of the seasonal harvest - this is why we call them CSA Shares.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            A CSA is not a vege box scheme, and we are not a supermarket. We are
            farmers growing real food. This means some vege will not be the
            same size, might look different to 'normal', and sometimes even come
            home with a slug - but it will always be grown in the best way, to
            ensure its nutritional value. It also means we can't have all vegetables
            available each week, and rely heavily on seasonal growing practices.
          </p>
          <p className="text-xl text-left leading-normal mb-5">
            A CSA member understands that we are striving to grow a diverse range of
            vegetables to have a diverse spread each week, but that there may be
            crop failures from time to time. Your financial contribution may not
            always equate to the value of the box - during times of abundance your
            share will be larger and during leaner times it will be less so.
          </p>
          <h2 className="font-serif text-olive-400 text-2xl mb-3">CSA Duration</h2>
          <p className="text-xl text-left leading-normal mb-5">
            There are four CSA periods in a year. These run for 13 weeks and run
            from December-Febuary, March-May, June-August and September-December.
          </p>
          
          <h2 className="font-serif text-olive-400 text-2xl mb-3">Pick-up &amp; Delivery</h2>
          <p className="text-xl text-left leading-normal mb-5">
            We offer both delivery and pick-up options for getting your CSA share. Delivery fees are to cover packaging and courier/delivery costs.
          </p>
          
          <p className="text-xl text-left leading-normal mb-5">
            <b>Pick-up:</b> Wednesdays from 3pm to 6pm, at the farm in Greytown
          </p>
          
          <p className="text-xl text-left leading-normal mb-5">
            <b>Wairarapa Delivery:</b> Wednesdays. $7 for Greytown, Carterton, Martinborough,
            Featherston between 1pm and 6:30pm.
          </p>
          
          <p className="text-xl text-left leading-normal mb-5">
            <b>Wellington Delivery:</b> $10 Delivery to Wellington from Thorndon south to Miramar/Island Bay on Wednesday's from 5pm to 12am. Address must be accesible outside business hours.
            <br/><a className="underline text-orange-400 hover:text-orange-500" href="https://www.google.com/maps/d/u/0/edit?mid=1763AlqnvjFp3Osiu5fOpTTw7hvtuwek&usp=sharing" target="_blank">Please check the delivery map here</a>

            {/* <p className="text-sm mb-2 font-bold">
              NB: If you are in Wellington but outside our current delivery zone, please either find a friend in the zone to have your share delivered to, or sign up with your address and we will add you to our pick up point waitlist. We are aiming to have a pick up point by mid Feb which will be in central Wellington.
            </p> */}
          </p>

          <p className="text-xl text-left leading-normal mb-5">
            <b>Lower Hutt Pickup:</b> $5 Delivery to <a className="underline text-orange-400 hover:text-orange-500" href="https://www.google.com/maps/place/Forage+Merchants+of+Wellington/@-41.2150814,174.8906204,17z/data=!3m1!4b1!4m6!3m5!1s0x6d38ab7d96e06819:0xff2cfa15d9f37931!8m2!3d-41.2150855!4d174.8931953!16s%2Fg%2F11n0fyhxtc?entry=ttu" target="_blank">Forage Merchants Lower Hutt</a>.
            Boxes will arrive between 1-3pm Wednesday (pick-up closing 4:30pm) and shares must be collected by 4pm Thursday. You will recieve a text when the boxes leave the farm.
          </p>
        </div>
      </div>

      <SmallHomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/SUMMER_SMALL.jpg"
        outerIcon={(<SeedsIcon width="calc(100% - 20px)" left="10" top="10" />)}
      >
        <h2
          class="text-white-400 text-[4rem] mb-8 leading-none font-serif"
        >
          SMALL SHARE
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
        6 to 9 seasonal vegetables including salad and cooking greens
        </p>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          $38/week - $494 for 13 week season
        </p>
      </SmallHomeTile>

      <SmallHomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/SUMMER_LARGE.jpg"
      >
        <LeafIcon width="279" rotate="90" left="-60" bottom="-190" />
        <WormIcon width="157" rotate="-76" right="-77" top="-170" />
        <h2
          class="text-white-400 text-[4rem] mb-8 leading-none font-serif"
        >
          LARGE SHARE
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          10 to 13 seasonal vegetables including salad and cooking greens
        </p>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          $58/week - $754 for 13 week season
        </p>
      </SmallHomeTile>

      <SmallHomeTile
        color="brown"
        image="https://cdn.vagabondvege.nz/images/pic/SALAD.jpg"
        outerIcon={(<SoilIcon width="calc(100% - 0px)" left="0" bottom="0" />)}
      >
        <h2
          class="text-white-400 text-[4rem] mb-8 leading-none font-serif"
        >
          EXTRA SALAD SHARE
        </h2>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          Add extra salad to your weekly share. 170g (340g in total per week)
        </p>
        <p
          class="text-white-400 text-2xl mt-0 mx-auto mb-10"
        >
          $7.50/week - $97.50 for 13 week season
        </p>
      </SmallHomeTile>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-center pt-5">
          <h2 className="font-serif text-olive-400 text-5xl mb-8">
            * The fine print
          </h2>
          <p className="text-xl text-center leading-normal mb-5">
            A CSA season is for 13 weeks. If you are away for one of the weeks, it’s
            your responsibility to nominate someone else to collect your weekly
            share
          </p>
          <p className="text-xl text-center leading-normal mb-5">
            At the end of the 13 week season, existing members are given priority
            before new members are invited to join.
          </p>
          <p className="text-xl text-center leading-normal mb-5">
            Your share can be paid upfront, in three installments or weekly
          </p>
        </div>
      </div>

      <div 
        className="relative z-20 m-0 overflow-hidden py-[70px] px-[70px] bg-olive-400 text-center"
      >
        <div className="relative z-20 flex flex-col items-center">
          <h2
            class="text-white-400 text-[3rem] mb-10 leading-none font-serif"
          >
            TRY A ONE-OFF BOX BEFORE JOINING THE CSA
          </h2>
          <button
            type="button"
            className="bg-white-400 text-olive-400 font-serif uppercase text-3xl rounded px-14 py-10 flex items-center"
            style={{
              boxShadow: "0px 0px 32px 0px rgba(245,232,206,0.8)"
            }}
            onClick={e => window.location = "https://app.vagabondvege.nz/csa/oneoff"}
          >
            ORDER A ONE-OFF&nbsp;&nbsp;&nbsp;&nbsp;
            <ChevronRightIcon width={32} height={32} />
          </button>
        </div>
      </div>
      
      <div 
        className="relative z-20 m-0 overflow-hidden py-[70px] px-[70px] bg-[#ff9500]"
      >
        <SeedsIcon width="calc(100% - 20px)" left="10" bottom="10" />
        <div className="flex flex-row flex-wrap content-center relative z-20">
          <Parallax speed={4} className="relative self-center text-center flex-1 basis-[50%] max-w-full">
            <h2
              class="text-white-400 text-[4.5rem] mb-10 leading-none font-serif"
            >
              SIGN UP FOR AN AUTUMN CSA SHARE
            </h2>
            <div className="flex gap-10 flex-nowrap items-center justify-center">
              <div className="border-[#f5e8ce] rounded-md border-3 text-center font-serif w-[76px] h-[76px]">
                <div className="bg-[#f5e8ce] text-olive-400 font-semibold italic text-sm leading-relaxed">Feb</div>
                <div className="text-olive-400 opacity-70 text-3xl pt-3 italic bg-[#f5e8ce]">28<sup className="ml-0.5">th</sup></div>
              </div>
              <div>
                <h4 className="text-white-400 text-3xl font-serif">to</h4>
              </div>
              <div className="border-[#f5e8ce] rounded-md border-3 text-center font-serif w-[76px] h-[76px]">
                <div className="bg-[#f5e8ce] text-olive-400 font-semibold italic text-sm leading-relaxed">May</div>
                <div className="text-olive-400 opacity-70 text-3xl pt-3 italic bg-[#f5e8ce]">22<sup className="ml-0.5">nd</sup></div>
              </div>
            </div>
          </Parallax>
          <Parallax speed={-6} class="self-center text-center flex-1 basis-[50%] justify-center flex">
            <button
              type="button"
              className="bg-white-400 text-olive-400 font-serif uppercase text-3xl rounded px-14 py-10 flex items-center self-center"
              style={{
                boxShadow: "0px 0px 32px 0px rgba(245,232,206,0.8)"
              }}
              onClick={e => window.location = "https://app.vagabondvege.nz/csa/register/new"}
            >
              Join Now&nbsp;&nbsp;&nbsp;&nbsp;
              <ChevronRightIcon width={32} height={32} />
            </button>
          </Parallax>
        </div>
      </div>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-left">
          <h1 className="font-serif text-olive-400 text-3xl text-center mb-5">Why join a CSA?</h1>
          <h4 className="font-serif text-olive-400 text-2xl mb-3">Advantages for you:</h4>
          
          <div className="flex gap-5 flex-wrap mb-8">
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              A variety of healthy and nutrient dense food
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Knowing where and how your food is grown
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Being able to visit our farm, and us!
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Getting exposed to new vegetables and new ways of cooking
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Fun for your whānau, kids favour veggies from ‘their’ farm
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Early workshop access &amp; one free workshop spot a season
            </span>
          </div>
          
          
          <h4 className="font-serif text-olive-400 text-2xl mb-3">Advantages for us:</h4>
          <div className="flex gap-5 flex-wrap mb-8">
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Payment in advance for the purchase of seed and other input funds
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              The ability to plan ahead for the amount of families to feed, meaning
              less excess and waste
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              Year-long support, also through winter when crops are less abundant
            </span>
            <span className="text-sm bg-blue-400 text-white-400 px-3 py-2 rounded">
              An opportunity to connect to the people who eat the kai we grow
            </span>
          </div>
        </div>
      </div>
      
      <div className="relative px-6 md:px-0 py-10 md:py-16 bg-white-400">
        <div className="max-w-4xl mx-auto text-left">
          <h1 className="font-serif text-olive-400 text-3xl text-center mb-5">CSA Values</h1>
          <p className="text-xl text-left leading-normal mb-5">
            We chose the CSA model for our farm because of these values that align
            with our kaupapa:
          </p>
          <h4 className="font-serif text-olive-400 text-2xl mb-3">Real Kai</h4>
          <p className="text-xl text-left leading-normal mb-5">
            In the age of the supermarket, it is hard to know where our kai comes
            from, who grew it and what’s actually in it. Farmers have been pushed
            into industrial monocropping because that is deemed the only
            economically viable way to grow food. We want to be part of the change
            towards a diverse network of small regenerative farms.
          </p>
          <h4 className="font-serif text-olive-400 text-2xl mb-3">Reciprocity</h4>
          <p className="text-xl text-left leading-normal mb-5">
            The sense of having each other’s backs. We look after you by providing
            you and your whānau with nutritious food, grown in a way that takes care
            of the land. In return, as a member you look after us by buying a long
            term share of produce so we have the means to grow. Knowing that we are
            backed by our local community, helps us get through the challenges of
            farming (like wind and rocks!).
          </p>
          <h4 className="font-serif text-olive-400 text-2xl mb-3">Resilience</h4>
          <p className="text-xl text-left leading-normal mb-5">
            The CSA model generates the support required for a small farm like us to
            be sustainable. It also means resilience in the sense of local
            self-sufficiency, meaning we are not at the whim of long overseas supply
            chains. For us, resilience is knowing we are backed despite events that
            are out of our control. If a sudden hail storm takes out part of the
            eggplant crop, everyone is disappointed together, and together we share
            the abundance of a crop that did very well that year.
          </p>
          <h4 className="font-serif text-olive-400 text-2xl mb-3">Low waste</h4>
          <p className="text-xl text-left leading-normal mb-5">
            Through knowing all of our customers personally, we can better suit our
            weekly offerings and packaging to minimize or eliminate unnessecary
            waste in our weekly food.
          </p>
        </div>
      </div>
    </div>
  )
}